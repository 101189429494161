@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300&display=swap);
body, h1, h2, h3, h4, h5, h6, p, img {
  padding: 0;
  margin: 0;
}
body {
  background-color: #fff;
  color: #1e1e1e;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}
