$color-grey-medium: #80868b;

$base-font-size: 16px;
$x-small-font-size: 0.75rem; //12px
$small-font-size: 0.875rem; // 14px
$default-font-size: 1rem;
$font-size-medium: 1.25rem;
$font-size-medium-large: 1.5rem;
$font-size-large: 2rem;
$font-size-xlarge: 2rem;
$font-size-xxlarge: 3rem;

$router-outlet-container-top-clearance: 3.125rem; // 50px

$heading-font-size-small-rem: 1.25rem; // 20px
$heading-font-size-medium-rem: 1.5rem; // 24px
$heading-font-size-large-rem: 2rem; // 32px

// Padding
$default-padding-mobile: 0.5rem;
$default-padding-tablet-medium-screen: 1rem;
$default-padding-large-screen: 1.5rem;

$padding-thin: 0.3125rem; // 5px
$padding-small: 0.625rem;// 10px

//Borders
$border-thinner: 0.0625rem; //1px
$border-thin: 0.125rem; // 2px

$default-border-radius: 4px;
$border-radius-thin: 0.125rem; // 2px

//Headings
$default-headings: 1rem;
$default-sub-headings: 0.75rem;
$default-sub-headings-lg: 0.875rem;
$default-font-size-small: 0.75rem;

//Spacing
$default-spacing: 1.25rem;

