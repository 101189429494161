@import 'assets/styles/fonts/fonts';
@import 'assets/styles/variables/variables';
@import 'assets/styles/colors/colors';

body, h1, h2, h3, h4, h5, h6, p, img {
  padding: 0;
  margin: 0;
}

body {
  background-color: $color-white;
  color: #1e1e1e;
  font-size: $base-font-size;
  font-family: $default-font-family;
}
